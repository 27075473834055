#header {
     text-align: center;

     grid-column: 1 / span 3;
     display: grid;
     position: relative;
     grid-template-rows: auto 1fr 1fr;
     grid-template-columns: repeat(6, 1fr);
     color: #ffffff;
}
.dropdown {
     position: relative;
     display: inline-block;
}
.dropdownshop {
     max-height: 0px;
     overflow: hidden;
     transition: 1s ease max-height;
     border: 1px solid red;
     width: 10vw;
}
.dropdownparentshop {
     max-height: 0px;
     overflow: hidden;
     transition: 1s ease max-height;

     width: 100%;
}
.dropdownparentabout {
     max-height: 0px;
     overflow: hidden;
     transition: 1s ease max-height;

     width: 100%;
}
.expande {
     max-height: 500%;
}
.dropdownelementshop {
     width: 100%;
     color: #b7b4b2;
    
     font-size: x-large;
}
.noline {
     text-decoration-line: none;
     color: #02899b;
}
.dropdown-content {
     max-height: 0px;
     position: absolute;
      
     min-width: 25vw;
     width: 100%;
     text-align: left !important;
     
}
.dropdown-menu-shop {
     position: absolute;


     width: 150%;
     text-align: left !important;
     font-family: amatic-sc, sans-serif;

     font-weight: 400;

     font-style: normal;
}
.dropdownelement {
     text-decoration-line: none;
     width: 100%;
     color: #b7b4b2;
     
     font-size: x-large;
}
.dropdownelement:hover {
     
     color: #02899b;
}
.dropd {
     text-decoration-line: none;

     color: #b7b4b2;
     
     font-size: x-large;
     width: 100%;
}

.logo {
     grid-row: 2 / span 2;
     grid-column: 3 / span 2;
}
.home {
     grid-row: 2;
     grid-column: 2 / span 1;
}
.gal {
     cursor: pointer;
     grid-row: 3;
     grid-column: 2 / span 1;
}
.about {
     cursor: pointer;
     grid-row: 2;
     grid-column: 5 / span 1;
}
.impr {
     grid-row: 3;
     grid-column: 5 / span 1;
}
.nav-link {
     text-decoration: none;
     font-family: amatic-sc, sans-serif;
     color: #02899b!important;;
     font-weight: 400;
     font-style: normal;
     align-self: center;
     padding-top: 10%;
     z-index: 999;
}

#social {
     grid-row: 1;
     grid-column: 5 / span 2;
     margin: 5px;
     display: flex;
     justify-content: center;
}
.social-icon {
     grid-row: 1;
     grid-column: 6;
     margin: 1rem;
     color: #02899b !important;
}

@media (max-width: 576px) {
     #header {
          grid-template-rows: auto 1fr 1fr;
          grid-template-columns: repeat(2, 1fr) !important;
     }
     #social {
          grid-row: 1;
          grid-column: 1 / span 2;
     }
     .logo {
          display: none;
     }
     .home {
          grid-row: 2;
          grid-column: 1 / span 1;
     }
     .gal {
          grid-row: 3;
          grid-column: 1 / span 1;
          margin-bottom: 30px;
     }
     .about {
          grid-row: 2;
          grid-column: 2 / span 1;
     }
     .impr {
          grid-row: 3;
          grid-column: 2 / span 1;
          margin-bottom: 30px;
     }
}

@media (max-width: 991.98px) {
     .social-icon {
          margin-top: 5% !important;
     }
}

@media (min-width: 992px) {
     .social-icon {
          margin-top: 10% !important;
     }
     #social {
          grid-row: 2;
          grid-column: 7 / span 1;
          margin: 5px;
          display: flex;
          justify-content: center;
     }
     #header {
          grid-template-rows: auto 1fr;
          grid-template-columns: repeat(7, 1fr);
     }
     .home {
          grid-row: 2;
          grid-column: 2 / span 1;
     }
     .logo {
          grid-row: 2;
          grid-column: 4 / span 1;
     }
     .gal {
          grid-row: 2;
          grid-column: 3 / span 1;
     }
     .about {
          grid-row: 2;
          grid-column: 5 / span 1;
     }
     .impr {
          grid-row: 2;
          grid-column: 6 / span 1;
     }
}

@media (min-width: 1200px) {
     .nav-link {
          padding-top: 5%;
          padding-bottom: 5%;
          font-size: xx-large;
     }
     .gal {
          border-left: 1px solid aliceblue;
     }
     .impr {
          border-left: 1px solid aliceblue;
     }
}
