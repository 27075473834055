
.intro2 {
     align-items: center;
     background: url(/public/img/Backround-mobil.jpg);
     height: auto;
     background-size: 105vw;
     background-attachment: fixed;
}
.imgdreli {
     width: 100%;
}
.paragraph{
     text-align: justify;
}
.cover3{
     grid-column-start: 1;
     grid-row-start: 2;
     width: 50vw;
     align-self: center;
    padding-left: 25vw;
     
}
.cover4{
     grid-column-start: 1;
     grid-row-start: 4;
     width: 50vw;
     align-self: center;
    padding-left: 25vw;
     
}
.cover5{
     grid-row: 2;
     width: 50vw;
     align-self: center;
    
     
}
.elem1{
     grid-row: 3;
     width: 50vw;
     align-self: center;
    padding-left: 25vw;
}
.elem2{
     grid-row: 5;
     width: 50vw;
     align-self: center;
    padding-left: 25vw;
}
.teaser2{
     font-size: 1.2vw;
     font-family: interstate, sans-serif;
     text-align: center;
     font-weight: 300;
     line-height: 2;
     margin-top: 0px;
     font-style: normal;
     padding-bottom: 1.2vw;
}

.cover2{
     grid-column-start: 1;
     grid-column-end: 8;
     grid-row-start: 1;
     width: 50vw;
     
}


.intro-content {
     grid-column: 1 / span 4;
     background-color: #02899b;
     height: auto;
}
.bild {
     width: 50%;
     max-width: 100%;
     display: block;
     float: left;
     margin-right: 20px;
     margin-bottom: 20px;
}

@media (min-width: 1200px) {
     .intro2{
     background: url(/public/img/Backround-web.jpg);
     align-items: center;
     height: 100%;
     background-size: 100vw;
     background-attachment: fixed;
     }
     .intro-content {
          grid-column: 3 / span 4;
     }
     .bild {
          width: 60%;
     }
     .teaser2{
          font-size: 1.2vw;
          font-family: interstate, sans-serif;
          text-align: center;
          font-weight: 300;
          line-height: 2;
          margin-top: 0px;
          font-style: normal;
          padding-bottom: 100px;
     }
}
