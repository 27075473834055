.wrap {
     display: grid;
     grid-template-rows: 1fr;
     grid-template-columns: auto auto;
     
     height: auto;
     padding: 2rem;
}

.imgdreli {
     width: 100%;
}
.h1{
     padding-left: 20vw;
}
.welcomee {
     grid-column: 1 / span 6;
     font-size: 4vw;
     color: #02899b;
     background: url(/public/img/Backround-mobil.jpg);
     background-attachment: fixed;
     height: auto;    
     background-size: 105vw;
          font-family: "ofelia-text", sans-serif;
          font-weight: 400;
          font-style: normal;
     
     
}
.intro-content {
     grid-column: 1 / span 4;

     height: auto;
}
.bild {
     width: 50%;
     max-width: 100%;
     display: block;
     float: left;
     margin-right: 20px;
     margin-bottom: 20px;
}
.megrid {
     grid-template-columns: repeat(5, 1fr);
     display: grid;
     
}
.me {
     grid-column: 2 / span 3;
}
.beautyme {
     font-size: 1.5vw;
     font-family: interstate, sans-serif;
     text-align: justify;
     font-weight: 300;
     line-height: 1.5;
     margin-top: 0px;
     font-style: normal;
}
@media (max-width: 576px) {
     .wrap {
          grid-template-columns: 1fr;
          grid-template-rows: 1fr auto;
     }
     .bild {
          width: 100%;
     }
}
@media (min-width: 768px) {
     .wrap {
          grid-template-columns: repeat(5, 1fr);
     }
     .intro-content {
          grid-column: 2 / span 3;
     }
}
@media (min-width: 992px) {
     .wrap {
          display: grid;
          grid-template-rows: auto 1fr 23vw;
          grid-template-columns: repeat(8, 1fr);
          background-color: #02899b;
          height: auto;
          padding: 2rem;
     }
     .intro-content {
          grid-column: 3 / span 4;
     }
     .bild {
          width: 60%;
     }
}
@media (min-width: 1400px) {
     .intro-content {
          grid-column: 3 / span 5;
     }
     .bild {
          width: 40%;
     }
     .welcomee {
          grid-column: 1 / span 6;
          font-size: 4vw;
          color: #02899b;
          background: url(/public/img/Backround-web.jpg);
          background-attachment: fixed;
          height: auto;
          
          background-size: 105vw;
               font-family: "ofelia-text", sans-serif;
               font-weight: 400;
               font-style: normal;
               grid-column: 1 / span 6;
               font-size: 4vw;
               color: #02899b;
               
     }
}
