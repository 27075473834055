/* Grundlegende Schriftarten und Layout */


/* Stil für den Impressum-Block */
.impressum-container {
    max-width: 800px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif; /* Moderne Schriftart */
}

.datenschutz-container{
    max-width: 800px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif; /* Moderne Schriftart */
}



/* Stil für die Überschrift */
h4 {
    font-size: 1.8rem;
    font-weight: 700;
    color: #4CAF50; /* Grün für das Überschrift */
    margin-bottom: 20px;
}

/* Absatzformatierung */
p {
    font-size: 1rem;
    margin-bottom: 15px;
    line-height: 1.6;
    text-align: justify;
}

/* Formatierung für fettgedruckte Textstellen */
b {
    font-weight: 700;
    color: #333333;
}

/* Formatierung für Links */




/* Stil für Absätze, die aufgelistet werden müssen */
ul {
    list-style-type: none;
    margin-left: 20px;
}

ul li {
    margin-bottom: 10px;
}

/* Stylische Trennlinie zwischen Absätzen */
hr {
    margin: 30px 0;
    border: 1px solid #dddddd;
}

/* Optionaler Stil für Telefon- und Email-Daten */
.contact-info {
    font-weight: 500;
}

/* Zusätzlicher Stil für den Hinweis zur Streitbeilegung */
.streitbeilegung {
    background-color: #f0f8ff;
    border-left: 5px solid #1E90FF;
    padding: 15px;
    margin-top: 25px;
}
