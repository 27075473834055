.intro {
     align-items: center;
     background: url(/public/img/Backround-mobil.jpg);
     background-color: #02899b;
     height: auto;
}
.imgdreli {
     width: 100%;
}

.cover{
     grid-column-start: 1;
     grid-column-end: 8;
     grid-row-start: 1;
     width: 99vw;
     
}
.home{
     height: auto;

}

.intro-content {
     grid-column: 1 / span 4;
     background-color: #02899b;
     height: auto;
}
.bild {
     width: 50%;
     max-width: 100%;
     display: block;
     float: left;
     margin-right: 20px;
     margin-bottom: 20px;
}

@media (min-width: 992px) {
     .intro {
          background-color: #02899b;
          height: auto;
          background: url(/public/img/Backround-web.jpg);
          background-size: 100vw;
          background-attachment: fixed;
     }
     .intro-content {
          grid-column: 3 / span 4;
     }
     .bild {
          width: 60%;
     }
}
