.intro {
     align-items: center;
     text-align: justify;
     background: url(/public/img/Backround-mobil.jpg);
     background-size: 100vw;
     background-attachment: fixed;
     height: auto;
     align-items: center;
     display: grid;
     grid-template-rows: 10vw 25vw 25vw 25vw 33vw;
}
.imgdreli {
     width: 100%;
}

.cover2{
     grid-column-start: 1;
     grid-column-end: 8;
     grid-row-start: 1;
     width: 50vw;
     align-self: center;
    
     
}

.wrapper{
   width:60vw;
   padding-left: 20vw;;  
}

.intro-content {
     grid-column: 1 / span 4;
     background-color: #02899b;
     height: auto;
}
.bild {
     width: 50%;
     max-width: 100%;
     display: block;
     float: left;
     margin-right: 20px;
     margin-bottom: 20px;
}
.liste{
     margin-left: 10vw;
     padding-left: 0px;
     text-align: left;
}
.paragraph{
     text-align: center;
     font-size: 1.3vw;
     color: #02899b;
     font-family: interstate, sans-serif;
     font-style: normal;
     width: 50vw;
     padding-left: 25vw;
     
}


@media (min-width: 1200px) {
     .intro{

     background: url(/public/img/Backround-web.jpg);
     text-align: justify;
     background-size: 100vw;
     background-attachment: fixed;
     height: auto;
     align-items: center;
     display: grid;
     grid-template-rows: 10vw 25vw 25vw 25vw 33vw;
     }
     .intro-content {
          grid-column: 3 / span 4;
     }
     .bild {
          width: 60%;
     }
}
