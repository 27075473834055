kontakt {
     grid-column: 11 / span 5;
}

.headline2 {
     font-family: amatic-sc, sans-serif;
     font-size: 4vw;
     font-weight: 400;

     font-style: bold;
}
.headline22 {
     font-family: amatic-sc, sans-serif;
     font-size: 4vw;
     font-weight: 400;

     font-style: bold;
}
.btn {
     font-family: amatic-sc, sans-serif;

     font-weight: 400;

     font-style: bold;
}
.form-control {
     display: block;
     width: 100%;
     height: calc(1.5em + 0.75rem + 2px);
     padding: 0.375rem 0.75rem;
     font-size: 1rem;
     font-weight: 400;
     line-height: 1.5;
     color: #495057;
     background-color: #fff;
     background-clip: padding-box;
     border: 1px solid #ced4da;
     border-radius: 0.25rem;
     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
     margin-bottom: 1vw;
     font-family: amatic-sc, sans-serif;
     font-size: xx-large;
     font-weight: 400;

     font-style: normal;
}
.form-control-text {
     display: block;
     width: 100%;
     height: 13vw;
     padding: 0.375rem 0.75rem;
     font-size: 1rem;
     font-weight: 400;
     line-height: 1.5;
     color: #495057;
     background-color: #fff;
     background-clip: padding-box;
     border: 1px solid #ced4da;
     border-radius: 0.25rem;
     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
     margin-bottom: 1vw;
     font-family: amatic-sc, sans-serif;
     font-size: xx-large;
     font-weight: 400;

     font-style: normal;
}
@media (max-width: 992px) {
     .form-control {
          width: 95%;
     }
     .form-control-text {
          width: 95%;
     }
     .btn {
          width: 98%;
          height: 5vw;
          font-size: 3vw;
     }
}

.form-group {
     margin-bottom: 1rem;
}

.form-text {
     display: block;
     margin-top: 0.25rem;
}
.form-check {
     position: relative;
     display: block;
     padding-left: 1.25rem;
}

.form-check-input {
     position: absolute;
     margin-top: 0.3rem;
     margin-left: -1.25rem;
}
.headline {
     grid-column: 1 / span 3;
     font-family: "ofelia-text", sans-serif;
     font-size: 4vw;
     font-weight: 400;
     text-align: center;
     font-style: normal;
     margin-top: 0px;
     margin-bottom: 4vw;
}
@media (min-width: 992px) {
     .headline2 {
          font-size: 2vw;
     }
     .headline {
          font-size: 8vw;
     }
     .btn {
          width: 98%;
          height: 3vw;
          font-size: 2vw;
     }
}
@media (max-width: 576px) {
     .headline2 {
          font-size: 5vw;
     }
     .headline {
          font-size: 15vw;
     }
}
