
.imgdreli {
     width: 100%;
}
.nolinee {
     
     color: rgb(179, 179, 180);
}
.teaser2{
     font-size: 1.2vw;
     font-family: interstate, sans-serif;
     text-align: center;
     font-weight: 300;
     line-height: 2;
     margin-top: 0px;
     font-style: normal;
     padding-bottom: 100px;
}

.cover2{
     grid-column-start: 1;
     grid-column-end: 8;
     grid-row-start: 1;
     width: 50vw;
     
}


.intro-content {
     grid-column: 1 / span 4;
     background-color: #02899b;
     height: auto;
}
.bild {
     width: 50%;
     max-width: 100%;
     display: block;
     float: left;
     margin-right: 20px;
     margin-bottom: 20px;
}

@media (min-width: 992px) {
     
     .intro-content {
          grid-column: 3 / span 4;
     }
     .bild {
          width: 60%;
     }
}
