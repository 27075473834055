.footer{
    font-size: 1vw;
    font-family: interstate, sans-serif;
    background-color: #02899b;
    color: rgb(255, 253, 253);
    display: grid;
    
    grid-template-rows: 2vh 11vw 11vw 11vw;
}
.rights{
    font-size: 1vw;
    grid-row: 4 / span 1; 
}
.nolineee {
    text-decoration-line: none;
    color: rgb(255, 253, 253);;
}
.first2{
    display: grid;
    grid-template-columns: 5vw 30vw 30vw 30vw 5vw;
    grid-row: 2 / span 1;
}
.secondfoot{
     
    grid-row: 3 / span 1;
    display: grid;
    grid-template-columns: 5vw 30vw 30vw 30vw 5vw;
}
.thirdfoot{
     
    grid-row: 4 / span 1;
    display: grid;
    grid-template-columns: 5vw 30vw 30vw 30vw 5vw;
}
.borderfoot{
    border-top:solid 3px rgb(255, 253, 253) ; 
    width: 70%;
}
.borderline{
    border-top:solid 3px rgb(255, 253, 253) ;
    grid-column: 2 / span 3 ;
}
.col1{
    grid-column: 2 / span 1; 
    display: grid;
    
    grid-template-rows: 2vw 1.5vw 1.5vw 1.5vw;
}
.col2{
    grid-column: 3 / span 1; 
    display: grid;
    
    grid-template-rows: 2vw 1.5vw 1.5vw 1.5vw;
}
.col23{
    grid-column: 3 / span 2; 
    display: grid;
    
    grid-template-columns: 40% 20% 20% 20%;
}
.column2{
    grid-column: 2 / span 1; 
    
}
.column3{
    grid-column: 3 / span 1; 
    
}
.column4{
    grid-column: 4 / span 1; 
    
}
.col3{
    grid-column: 4 / span 1;
    display: grid;
    
    grid-template-rows: 2vw 1.5vw 1.5vw 1.5vw; 
}
.row11{
    grid-row: 1 / span 1; 
}
.row22{
    grid-row: 2 / span 1;
    
}
.row33{
    grid-row: 3 / span 1; 
}
.row44{
    grid-row: 4 / span 1; 
}
.row55{
    grid-row: 5 / span 1; 
}
.headlinefoot{
    
    font-size: 1.3vw;

    font-family: interstate, sans-serif;
}
